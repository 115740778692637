import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  NavItem,
  NavLink,
  Table,
  Input,
  Label,
  Col,
  CardTitle,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import AnaliseBox from "./comp/AnaliseBox"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import { __formatDate } from "constants/constantfun"
const Dashboard = props => {
  const {
    leadOverView,
    setLeadOverView,
    callOverView,
    setCallOverView,
    sourceFileOverView,
    setSourceFileOverView,
  } = useContext(dataContext)

  const __handleGetCallOverViewData = () => {
    __postApiData(`/client_plan_expire`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setCallOverView({ list: res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetLeadOverviewData = () => {
    __postApiData(`/client_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setLeadOverView({ ...res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetSouceFileOverviewData = () => {
    __postApiData(`/api/v1/admin/g_lead_file_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setSourceFileOverView({ list: res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    // !leadOverView?.isLoaded && __handleGetLeadOverviewData()
    // !callOverView?.isLoaded && __handleGetCallOverViewData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid></Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
