import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { __deleteApiData, __getApiData, __postApiData } from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"

const WalletHistory = props => {
  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isEdit: false,
    edit: null,
    isDelete: false,
    delete_id: null,
  })
  const { leads, isLoading, delete_id } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center"> Transaction Type</div>,
        accessor: "type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Transaction Amount</div>,
        accessor: "amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">₹{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center">Customer Id</div>,
        accessor: "customer_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Customer Name</div>,
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {__formatDate(cellProps.value) || "-"}
            </div>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_wallet_history`)
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data,
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No contact Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(WalletHistory)
